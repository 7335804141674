export const throttle = (fn, wait) => {
  let time = Date.now();

  return (...rest) => {
    if (time + wait - Date.now() < 0) {
      fn(...rest);
      time = Date.now();
    }
  };
}
