import { register } from '@netcentric/component-loader';
import { throttle } from 'commons/libs/throttle';
import { classes } from './corp-header.config';

class HeaderCorp {
  constructor(el) {
    this.el = el;
    this.lastScrollTop = 0;
    this.headerHeight = el.offsetHeight;
    this.init();
    this.debounce;
  }

  init() {
    this.setRefs();
    this.setEventListeners();
  }

  setRefs() {
    this.header = this.el.parentElement;
  }

  setEventListeners() {
    window.addEventListener('scroll', throttle(this.handleScroll.bind(this), 100), { passive: true });
  }

  handleScroll() {
    const top = window.scrollY;
    clearTimeout(this.debounce);
    delete this.debounce;

    // Scrolling up
    if (this.lastScrollTop > top && top > this.headerHeight) {
      this.header.classList.add(classes.stickyHeader);
    // Scrolling down
    } else {
      this.header.classList.remove(classes.stickyHeader);
    }
    // For Mobile or negative scrolling
    this.lastScrollTop = Math.max(0, top);

    // When scrolling too fast, or by clicking on the scroll bar, the last jump
    //  will not trigger the sticky header to hide.
    this.debounce = setTimeout(() => {
      if (window.scrollY === 0 || window.scrollY < this.headerHeight) {
        this.header.classList.remove(classes.stickyHeader);
      }
    }, 500);
  }
}

register({ HeaderCorp });
